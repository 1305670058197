import React from "react"
import Seo from "../../components/seo"
import Trusted from "../../components/trusted"
import Contacts from "../../components/contacts"
import Glance from "../../components/glance"
import Slider from "../../components/slider"
import { useState, useContext } from "react"
import TryPdf from "../../components/shared/TryPdf"
import { GlobalAuthContext } from "../../Contexts/GlobalAuthContext"
import StickyCtaClosable from "../../components/landing-pages/stickyCtaClosable"
import HeaderLp from "../../components/landing-pages/headerLp"
import saleDate from "../../utils/saleDate"
const FeaturesPage = ({ pageContext, location }) => {
  //global context
  const { setScroll, closedSticky } = useContext(GlobalAuthContext)
  const [myPopUp, setMyPopUp] = useState("hide")

  //close popup function
  function closePopup() {
    setMyPopUp("hide")
    setScroll("hide")
  }
  //open popup function
  function openPopup(e) {
    e.preventDefault()
    setMyPopUp("show")
    setScroll("show")
  }

  return (
    <>
      <Seo title="Features" noindex={true} />
      {closedSticky == false && (
        // <StickyCtaClosable date={pageContext.endSale}></StickyCtaClosable>
        <StickyCtaClosable
          date={`Get 38% off for a limited time! Sale ends ${saleDate()}.`}
        ></StickyCtaClosable>
      )}
      <HeaderLp wrapLocation={location} closedSticky={closedSticky}></HeaderLp>
      <Glance open={openPopup} location={pageContext.location}></Glance>
      <Slider></Slider>
      <Trusted></Trusted>
      {myPopUp === "show" && <TryPdf close={closePopup} />}
    </>
  )
}

export default FeaturesPage
